:root {
  --color-champagne: rgba(251, 248, 245, 0.8);
  --color-antique-white: rgba(250, 235, 215, 1);

  --color-background: #FBF8F5;
  --color-gradient: rgba(251, 248, 245, 0.2);

  /* 
  --color-champagne: rgba(247, 231, 206, 0.8);
  --color-antique-white: rgba(250, 235, 215, 1);

  --color-background: #FAF0E6;
  --color-gradient: rgba(247, 231, 206, 0.2); 
  */
}

@font-face {
  font-family: "SignatureCollection";
  src: url('./assets/fonts/SignatureCollection.otf') format('truetype');
}

@font-face {
  font-family: "AlegriaSans";
  src: url('./assets/fonts/AlegreyaSans-Light.ttf') format('truetype');
}

.font-signature-collection {
  font-family: "SignatureCollection";
}

.font-alegria-sans {
  font-family: "AlegriaSans";
}

body {
  margin: 0;
  font-family: 'AlegriaSans';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--color-background);
  /* background-image: "linear-gradient(to bottom, rgba(247, 231, 206, 0.9), rgba(247, 231, 206, 0.2))", */
  /* background-image: "linear-gradient(to bottom, #F1E9D2, #f0ead6)"; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
