.footer-text {
    font-size: x-large;
    color: black;
}

.margin-right-20px {
    margin-right: 20px;
}

.margin-left-20px {
    margin-left: 20px;
}

.margin-right-10px {
    margin-right: 10px;
}

.margin-left-10px {
    margin-left: 10px;
}